<template lang="pug">
header.FcwProfileNav
  .FcwProfileNav__menuStart
    FcwRouterLink(
      :location="homeRoute"
      hide-arrow
      color="primary"
      hover-color="primary--dark-1"
    )
      FcwClientLogo
    FcwChip.FcwProfileNav__previewChip(
      v-if="isPreview"
      :label="t('common__preview_mode')"
    )

  .FcwProfileNav__menuEnd
    template(v-if="helpCenterOptions.length > 0")
      FMenu(
        v-if="helpCenterOptions.length > 1"
        :options="helpCenterOptions"
        :offset-skid="8"
        :offset-distance="36"
        width="auto"
        color="neutral--light-5"
        text-color="neutral--dark-3"
        prevent-selection
        @before-select-option="handleHelpCenterSelectOption"
      )
        template(#activator) 
          FLink.FcwProfileNav__link(
            hide-arrow
            color="neutral--dark-3"
          ) {{ t('nav__help__title') }}
      FcwRouterLink(
        v-else
        :location="helpCenterOptions[0].value"
        hide-arrow
        color="neutral--dark-3"
      ) {{ t('nav__help__title') }}
    FMenu.FcwProfileNav__userMenu(
      v-model="isUserMenuOpen"
      :options="userMenuOptions"
      :class="{ 'FcwProfileNav__userMenu--open': isUserMenuOpen }"
      :offset-skid="8"
      :offset-distance="36"
      width="auto"
      color="neutral--light-5"
      text-color="neutral--dark-3"
      prevent-selection
      @before-select-option="handleUserMenuSelectOption"
    )
      template(#activator)
        FLink.FcwProfileNav__userMenuToggle(
          ref="userMenuActivator"
          hide-arrow
          tabindex="0"
          color="primary"
        )
          FAvatar(
            :letter="userName"
            :size="24"
            color="primary"
            text-color="neutral--light-5"
          )
          .FcwProfileNav__userName(v-if="userName") {{ userName }}
          FIcon.FcwProfileNav__userMenuToggle__chevron(name="chevronDown")
      template(#option="{ option }")
        FcwRouterLink.FcwProfileNav__userMenuOption(
          v-if="option.value === 'editUserInfo'"
          :location="{ name: 'account-settings' }"
          hide-arrow
          color="neutral--dark-3"
        )
          FIcon(name="user")
          span {{ option.label }}
        .FcwProfileNav__userMenuOption(v-else-if="option.value === 'logout'")
          FIcon(name="logout")
          span {{ option.label }}
</template>

<style lang="stylus">
.FcwProfileNav
  display flex
  justify-content space-between
  align-items center
  height rem(80)
  elevation-light(3)
  border-radius 0 0 rem(24) rem(24)
  padding-left rem(24)
  padding-right rem(16)
  background var(--color--neutral--light-5)

  > * + *
    margin-left rem(24)

.FcwProfileNav__menuStart
  display flex
  align-items center
  gap rem(16)

.FcwProfileNav__menuEnd
  display flex
  align-items center
  color var(--color--neutral--dark-2)
  font-weight 400
  overflow auto
  padding rem(8)

  > *
    white-space nowrap
    flex-grow 0

  > * + *
    margin-left rem(32)
    white-space nowrap

.FcwProfileNav__link
  font-weight 400

.FcwProfileNav__userMenu
  .FMenu__activator
    display flex
    cursor pointer

  .FMenu__option
    padding 0

  &--open
    .FcwProfileNav__userMenuToggle__chevron
      transform rotate(180deg)

.FcwProfileNav__userMenuToggle__chevron
  transition transform 0.3s var(--transition--ease-out)

.FcwProfileNav__userMenuToggle
  margin-left rem(8)
  font-weight 400

.FcwProfileNav__userName
  margin 0 rem(8)

.FcwProfileNav__userMenuOption
  padding rem(8) rem(16) rem(8) rem(8)
  display flex
  align-items center

  .FIcon
    margin-right rem(8)

  a&
    font inherit
    text-decoration none

    &:hover
      color inherit
</style>

<script setup lang="ts">
import type { FcwRouterLinkProps } from '@/components/molecules/FcwRouterLink.vue';
import type { FMenuOption } from '@fifteen/design-system-vue';
import type { RouteLocationRaw } from 'vue-router';

export interface FcwProfileNavLink {
  /**
   * Title of the link
   */
  title: string;
  /**
   * Location of the link
   */
  location: FcwRouterLinkProps['location'];
}

export interface FcwProfileNavProps {
  /**
   * Currently connected user name
   */
  userName?: string;
  /**
   * Route to Home. Route will be applied on menuStart block
   */
  homeRoute?: RouteLocationRaw;
  /**
   * Links of the menu
   */
  links?: FcwProfileNavLink[];
}

const emit = defineEmits<{
  (name: 'logout'): void;
}>();

withDefaults(defineProps<FcwProfileNavProps>(), {
  userName: '',
  homeRoute: '',
  userRoute: '',
  links: () => [],
});

const { t } = useClientI18n();
const { isPreview } = useGlobalStore();

const isUserMenuOpen = ref(false);
const route = useRoute();

const userMenuOptions = computed<FMenuOption[]>(() => [
  ...(clientState.modules.accountSettings && route.name !== 'account-settings'
    ? [
        {
          label: t('profile__edit_personal_info'),
          value: 'editUserInfo',
        },
      ]
    : []),
  {
    label: t('profile__logout'),
    value: 'logout',
  },
]);

const { clientState } = useClientStore();

const { zendeskSharingHelpCenterUrl, zendeskLeasingHelpCenterUrl } =
  useRuntimeConfig().public;

const helpCenterOptions = computed<FMenuOption[]>(() => [
  ...(clientState.services.sharing && zendeskSharingHelpCenterUrl
    ? [
        {
          label: t('common__sharing'),
          value: zendeskSharingHelpCenterUrl,
        },
      ]
    : []),
  ...(clientState.services.leasing && zendeskLeasingHelpCenterUrl
    ? [
        {
          label: t('common__leasing'),
          value: zendeskLeasingHelpCenterUrl,
        },
      ]
    : []),
]);

async function handleHelpCenterSelectOption(value: string): Promise<void> {
  await navigateTo(value, { external: true, open: { target: '_blank' } });
}

async function handleUserMenuSelectOption(
  value: 'editUserInfo' | 'logout'
): Promise<void> {
  isUserMenuOpen.value = false;
  switch (value) {
    case 'logout':
      emit('logout');
      break;
  }
}
</script>
